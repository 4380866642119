import logo from './logo.svg';
import './App.css';
import DefaultLayout from './theme/DefaultLayout';
import { IsAuthenticated } from './component/Config';
import Login from './views/Login';

const App = () => (IsAuthenticated() && IsAuthenticated() !==null) ? 
<DefaultLayout />:<Login/>

export default App;
