import React, { useEffect, useState } from 'react';

export default function MenuList() {
    const [isSuper, setisSuper] = useState(false)

    useEffect(() => {
        //    if(getUser() ==null){
        //     setisSuper(true)

        //    }
    }, [])

    var menu = [
        {
            code: '1', role: "Dashboard", src: "dashboard", icon: "menu-icon tf-icons bx bx-home-smile", name: "Dashboard", link: "dashboard",
            submenu: []
        },
        {code:'2',role:"Members Directory", src:"members", name:'Members Directory',  icon: "menu-icon tf-icons bx bxs-dice-5", link:"members",
            submenu:[
                {index:0, code:'21',role:"Members",src:"Members ",link:"members", name:"Members "},
                {index:1, code:'22',role:"Agents",src:"agents",link:"agents", name:"Agents"},
                {index:2, code:'23',role:"Executive",src:"executive",link:"executive", name:"Executive"},
            ]
            },

        // {
        //     code: '3', role: "Agents", src: "agents", name: 'Agents', icon: "flaticon-381-smartphone", link: "agents",
        //     submenu: []
        // },
        {code:'3',role:"Events", src:"events", name:'Events', icon: "menu-icon tf-icons bx bxs-calendar-event",link:"Events",
            submenu:[]
            },
            {code:'4',role:"Communication", src:"Communication", name:'Communication',  icon: "menu-icon tf-icons bx bx-message-square-dots",link:"communication",
            submenu:[
                {index:0,code:'41',role:"Announcements",src:"Announcements",link:"announcements", name:"Announcements"},
                {index:1,code:'42',role:"SMS",src:"SMS",link:"sms", name:"SMS"},
            ]
            },
           
            {code:'5',role:" Election HQ", src:"Election HQ", name:' Election HQ',  icon: "menu-icon tf-icons bx bxs-wallet",link:"election",
            submenu:[
                {index:0, code:'51',role:"Election Dashboard",src:"Election Dashboard",link:"sms", name:"Election Dashboard"},
                {index:1, code:'52',role:"Vote Details",src:"Vote Details",link:"sms", name:"Vote Details"},
                {index:2, code:'53',role:"Registered Voters",src:"Registered Voters",link:"sms", name:"Registered Voters"},
            ]
            },

            {code:'10',role:"Voter Register", src:"Voter Register", name:'Voter Register',  icon:"menu-icon tf-icons bx bxs-card",link:"transactions",
            submenu:[
                {index:0, code:'101',role:"Register Dashboard ",src:"Register Dashboard",link:"sms", name:"Register Dashboard"},
                {index:1, code:'102',role:"Duplicates",src:"Duplicates",link:"sms", name:"Duplicates"},
            ]
            },
           
            {code:'6',role:"Report", src:"bank", name:'Report',  icon: "menu-icon tf-icons bx bx-file",link:"report",
            submenu:[
                {index:0,code:'61',role:"Membership Reports",src:"members",link:"members", name:"Membership Reports"},
                {index:1,code:'62',role:"Event Report",src:"eventreport",link:"eventreport", name:"Event Report"},
                {index:2,code:'63',role:"Financial Report",src:"financial",link:"financialreport", name:"Financial Report"},
                // {code:'64',role:"Attendance Report",src:"attendance",link:"attendance", name:"Attendance Report"},
            ]
            },
       
        {
            code: '7', role: "Project", src: "Project", name: 'Project', icon: "menu-icon tf-icons bx bx-folder", link: "operations",
            submenu: [
                { index:0, code: '71', role: "Ongoing Project", src: "Ongoing Project ", link: "endofbusiness", name: "Ongoing Project" },
                // {code:'2',role:"Reassign Agent",src:"production",link:"reassignagent", name:"Reassign Agent"},
                // {code:'3',role:"Import Customer",src:"Import Customer",link:"importcustomer", name:"Import Customer"},
                // {code:'4',role:"Import Accounts",src:"Import Accounts",link:"importaccounts", name:"Import Accounts"},
            ]
        },
        {
            code: '8', role: "Preference", src: "Preference", name: 'Preference', icon: "menu-icon tf-icons bx bx-cog", link: "Preference",
            submenu: [
                { index:0, code: '81', role: "Region", src: "Region", link: "region", name: "Region" },
                { index:1, code: '82', role: "District", src: "District", link: "branches", name: "District" },
                { index:2, code: '83', role: "Constituencies", src: "Constituencies", link: "areas", name: "Constituencies" },
                { index:3, code: '84', role: "Polical Parties", src: "Polical Parties", link: "products", name: "Polical Parties" },
                { index:4, code: '85', role: "Election Configuration", src: "Election Configuration", link: "products", name: "Election Configuration" },
               
            ]
        },
        {
            code: '9', role: "Auth Management", src: "Auth Management", name: 'Auth Management', icon: "menu-icon tf-icons bx bxs-user-detail", link: "users",
            submenu: [
                {index:0, code: '91', role: "Role", src: "Role", link: "role", name: "Role" },
                {index:1, code: '92', role: "Users", src: "Users", link: "users", name: "Users" },
                // { code: '13', role: "Misc", src: "Misc", link: "misc", name: "Misc" },
                //{code:'34',role:"Attendance",src:"Attendance",link:"attendance", name:"Attendance"}
            ]
        },
       
        {
            code: '100', role: "Settings", src: "Settings", name: 'Settings', icon: "menu-icon tf-icons bx bx-home-smile", link: "settings",
            submenu: [
                { code: '101', role: "Company Settings", src: "Company Settings", link: "company", name: "Company Settings" },
                // { code: '12', role: "Users", src: "Users", link: "users", name: "Users" },
                // { code: '13', role: "Misc", src: "Misc", link: "misc", name: "Misc" },
                //{code:'34',role:"Attendance",src:"Attendance",link:"attendance", name:"Attendance"}
            ]
        }
    ]



    return menu;
}