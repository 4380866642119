import React, { Suspense, useState } from 'react';
import { HashRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import SideMenu from './SideMenu';
import TopNav from './TopNav';
import $ from 'jquery'

const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));
const Members = React.lazy(() => import('../views/Members/MemberCon'));
const Agent = React.lazy(() => import('../views/Members/AgentCon'));
const Users = React.lazy(() => import('../views/AuthManagment/UserCon'));
const Roles = React.lazy(() => import('../views/AuthManagment/RoleCon'));
const Region = React.lazy(() => import('../views/Preference/Regions/RegionList'));
const ConsituencyCon = React.lazy(() => import('../views/Preference/Consituency/ConsituencyCon'));
const DistrictList = React.lazy(() => import('../views/Preference/Districts/DistrictList'));
const PartyList = React.lazy(() => import('../views/Preference/Party/PartyList'));
const ElectionConfig = React.lazy(() => import('../views/Preference/Election/ElectionConfig'));
const ElectionCon = React.lazy(() => import('../views/ElectionsHQ/ElectionCon'));
const Events = React.lazy(() => import('../views/Event/EventCon'));
const Communications = React.lazy(() => import('../views/Communications/CommunicationCon'));
const Projects = React.lazy(() => import('../views/Projects/ProjectCon'));
const VoteDetailsCon = React.lazy(() => import('../views/ElectionsHQ/VoteDetailsCon'));
const RegisteredVoters = React.lazy(() => import('../views/ElectionsHQ/RegisteredVoters'));
const VoterRegister = React.lazy(() => import('../views/VoterRegister/VoterRegister'));
const Duplicate = React.lazy(() => import('../views/VoterRegister/Duplicates'));
// import TopMenu from './TopMenu';
// import SideMenu from './SideMenu';
// import routes from './routes';
// import logo from './logo2.png'

function DefaultLayout() {
    const [tabname, setTabname] = useState({ 'tab0-1': { id: '0-1', name: 'Home' } });
    const [activetab, setActivetab] = useState('0-1');

    let pageItems = {
        '0': [<ElectionCon />,],
        '1': [<ElectionCon />,],
        '2': [<Members />, <Agent />],
        '3': [<Events />],
        '4': [<Communications />,],
        '5': [<ElectionCon />,<VoteDetailsCon/>,<RegisteredVoters/>],
        '10': [<VoterRegister />,<Duplicate/>],
        '6': [<Members />,],
        '7': [<Projects />,],
        '8': [<Region />, <DistrictList />,<ConsituencyCon/>,<PartyList/>,<ElectionConfig/>],
        '9': [<Roles />,<Users />],


    }

    const navClickHandler = (num, name,idx) => {
        let tname ={}// {...tabname};
        tname[`tab${num}`] = { 'id': num, 'name': name,'state':'active',index:idx };
        setTabname(tname);
    }

    const loader = () => <div id="loader"></div>;
    // let  gitm = tabname.id.split('-');
    return (
        // 274151
        <Suspense fallback={loader}>
            <HashRouter>
                <div class="layout-wrapper layout-content-navbar  ">
                    <div class="layout-container">

                        <SideMenu navClickHandler={navClickHandler} />
                        <div class="layout-page">
                            <TopNav />
                            <div class="content-wrapper">
                                <div class="container-xxl flex-grow-1 container-p-y">
                                    {Object.keys(tabname).map((key, idx) => {
                                        let itmid = tabname[key].id, gitm = itmid.split('-'),index=tabname[key].index;
                                        return (
                                            <div>
                                                {pageItems[`${gitm[0]}`][`${(parseInt(index))}`]}
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                        <div class="layout-overlay layout-menu-toggle"></div>
                        <div class="drag-target"></div>
                    </div>
                </div>
            </HashRouter>
        </Suspense>
    );
}

export default DefaultLayout;
