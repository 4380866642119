import React, { useEffect, useState } from 'react';
import logo from './../logo.png'
import { DoLogin } from '../component/Config';

function Login() {
    const [formitems, setformitems] = useState({ username: "", password: "" })
    const [checkpwd, setcheckpwd] = useState(false)

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setformitems(prevState => ({ ...prevState, [name]: value }));
    }
    const Dologin = (e) => {
        e.preventDefault();
        DoLogin(formitems)
    }
  
    return (

        <div class="container-xxl">
            <link rel="stylesheet" href="../../assets/vendor/css/pages/page-auth.css"></link>
            <div class="authentication-wrapper authentication-basic container-p-y">
                <div class="authentication-inner">
                    <div class="card px-sm-6 px-0">
                        <div class="card-body">
                           
                            <div class="app-brand justify-content-center">
                                <a href="index.html" class="app-brand-link gap-2">
                                    <span class="app-brand-logo demo">

                                      
                                    </span>
                                    <span class="app-brand-text demo text-heading fw-bold"><img src={logo} /></span>
                                </a>
                            </div>

                            <h4 class="mb-1">Welcome to admin! 👋</h4>

                            <form id="formAuthentication" class="mb-6" onSubmit={Dologin}>
                                <div class="mb-6">
                                    <label for="email" class="form-label">Email or Username</label>
                                    <input type="text" class="form-control" id="email" name="username" value={formitems.username}  onChange={handleChange} placeholder="Enter your email or username" autofocus />
                                </div>
                                <div class="mb-6 form-password-toggle">
                                    <label class="form-label" for="password">Password</label>
                                    <div class="input-group input-group-merge">
                                        <input type={(checkpwd)?"text":"password"} id="password" value={formitems.password}  onChange={handleChange}  class="form-control" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" />
                                        <span class="input-group-text cursor-pointer"><i onClick={()=>setcheckpwd(!checkpwd)} class={(checkpwd)?"bx bx-show":"bx bx-hide"}></i></span>
                                    </div>
                                </div>
                                <div class="mb-8">
                                    <div class="d-flex justify-content-between mt-8">
                                        {/* <div class="form-check mb-0 ms-2">
                                            <input class="form-check-input" type="checkbox" id="remember-me" />
                                            <label class="form-check-label" for="remember-me">
                                                Remember Me
                                            </label>
                                        </div> */}
                                        {/* <a href="auth-forgot-password-basic.html">
                                            <span>Forgot Password?</span>
                                        </a> */}
                                    </div>
                                </div>
                                <div class="mb-6">
                                    <button class="btn btn-primary d-grid w-100"  type="submit">Login</button>
                                </div>
                            </form>


                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Login