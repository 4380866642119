import React, { useState, useEffect } from 'react'
import MenuList from './MenuList'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import logo from './../logo.png'

function SideMenu(props) {
    const [menulist, setmenulist] = useState(new MenuList())
    const [active, setactive] = useState([])

    useEffect(() => {
        // $("li").removeClass("mm-active");
        // $("#mun1").addClass("mm-active");
        let menuItem=[]
        $('ul#menu').on('click', 'li', (ex) => {
            $('ul#menu li ul li').on('click', (e) => { });
            var id = $(ex.currentTarget).attr('id')
            if(id !=undefined){
                if(id.includes("sub")){
                }else{
                    $('.menu-sub').slideUp().removeClass('open');
                }
            }
           
        });

        $('.menu-toggle').on('click', function (event) {
            var id = $(event.currentTarget).attr('id')

            var $submenu = $(this).next('.menu-sub');
            if ($submenu.length) {
                if($('#'+$submenu.attr('id')).hasClass("open")){
                    $('#'+$submenu.attr('id')).removeClass('open');
                }
            }

            $('.menu-sub').not($submenu).slideUp().removeClass('open');

            if ($submenu.hasClass('open')) {
                // alert("yes")
                console.log("not");
            } else {
                $("#" + id).addClass("open");
                //$submenu.slideDown().addClass('open');
                $submenu.slideDown(300).addClass('open').css('height', 'auto');
            }
        });

        // $('body').on('click', function (event) {
        //     if (!$(event.target).closest('.menu-item').length) {
        //         $('.menu-sub').slideUp().removeClass('open');
        //     }
        // });


    }, [])

    // let feat=JSON.parse(getFeatures())
    return (

        <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">


            <div class="app-brand demo ">
                <a href="#" class="app-brand-link">
                    <span class="app-brand-logo demo" style={{marginLeft:"50px",paddingBottom:"5px"}}>
                        <img src={logo} width={"50%"} />
                    </span>
                    <span class="app-brand-text demo menu-text fw-bold ms-2">NDC</span>
                </a>

                <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto">
                    <i class="bx bx-chevron-left bx-sm d-flex align-items-center justify-content-center"></i>
                </a>
            </div>

            <div class="menu-inner-shadow"></div>



            <ul class="menu-inner py-1" id="menu">

                {menulist.map(mn => (
                    // feat[mn.code] !== undefined &&
                    <>
                        <li id={(mn.submenu != '') ? "sub" + mn.code : "mun" + mn.code} class="menu-item">
                            {(mn.submenu == '') ?
                                <Link  aria-expanded="false" class="menu-link" onClick={mn.submenu.code == undefined || mn.code === '1' ? () => props.navClickHandler(`${mn.code}-1`, mn.name,mn.index) : ''}>

                                    <i class={mn.icon}></i>
                                    <span class="nav-text">{mn.name}</span>
                                </Link> :
                                <a class="menu-link menu-toggle" href="javascript:void(0);">
                                    <i class={mn.icon}></i>
                                    {/* <i class="menu-icon tf-icons bx bx-layout"></i> */}
                                    <span class="nav-text">{mn.name}</span>
                                </a>
                            }
                            {mn.submenu != '' &&
                                <ul aria-expanded="false" className='menu-sub' id={(mn.submenu != '') ? "subidd" + mn.code : "mun" + mn.code} >
                                    {mn.submenu.map(smenu => ( //feat[mn.code].includes(smenu.code) &&
                                        <li class="menu-item">
                                            <Link  class="menu-link" onClick={() => props.navClickHandler(`${mn.code}-${smenu.code}`, smenu.name,smenu.index)}>{smenu.name}</Link>
                                        </li>
                                    )
                                    )}
                                </ul>
                            }
                        </li>



                    </>
                ))}

              

                {/*     <li class="menu-item">
                        <a href="app-email.html" class="menu-link">
                            <i class="menu-icon tf-icons bx bx-envelope"></i>
                            <div class="text-truncate" data-i18n="Email">Email</div>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a href="app-chat.html" class="menu-link">
                            <i class="menu-icon tf-icons bx bx-chat"></i>
                            <div class="text-truncate" data-i18n="Chat">Chat</div>
                        </a>
                    </li>
                    <li class="menu-item">
                        <a href="app-calendar.html" class="menu-link">
                            <i class="menu-icon tf-icons bx bx-calendar"></i>
                            <div class="text-truncate" data-i18n="Calendar">Calendar</div>
                        </a>
                    </li>
                   */}



            </ul>



        </aside>
    )
}

export default SideMenu